import Link from 'next/link';
import { memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto 1rem;
  max-width: ${(props) => props.theme.maxWidth};

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 2rem;
  }
`;

const Header = styled.h2`
  font-size: calc(1.5rem + 1vw);
  font-weight: 700;
  letter-spacing: -0.014em;
  line-height: 1.2;
  margin-bottom: 0;
  padding: 0.5rem;
  text-align: center;
`;

const Anchor = styled.a`
  color: inherit;
  text-decoration: none;
`;

type SecondaryTitleProps = {
  children: React.ReactNode;
  url?: string;
};

const SecondaryTitle = ({ children, url }: SecondaryTitleProps) => {
  return (
    <Wrapper>
      <Header>
        {url ? (
          <Link href={url} passHref legacyBehavior>
            <Anchor>{children}</Anchor>
          </Link>
        ) : (
          children
        )}
      </Header>
    </Wrapper>
  );
};

export default memo(SecondaryTitle);
